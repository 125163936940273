import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// import store from '../store/index.js';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/activate/:hash',
		name: 'Home',
		component: Home,
		meta: {
			activate: true
		}
	},
	{
		path: '/reset/:hash',
		name: 'Home',
		component: Home,
		meta: {
			reset: true
		}
	},
	{
		path: '/user-levels',
		name: 'UserLevels',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "user-levels" */ '../views/UserLevels.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/play-level/:id/:slug',
		name: 'PlayLevel',
		component: () =>
			import(/* webpackChunkName: "level-view" */ '../views/PlayLevel.vue')
	},
	{
		path: '/editor/:id?',
		name: 'Editor',
		component: () =>
			import(/* webpackChunkName: "editor" */ '../views/Editor.vue')
		// beforeEnter: (to, from, next) => {
		// 	if (store.state.userData) {
		// 		next();
		// 	} else {
		// 		next({ name: 'Home' });
		// 	}
		// }
	},
	{
		path: '/my-levels/',
		name: 'MyLevels',
		component: () =>
			import(/* webpackChunkName: "my-levels" */ '../views/MyLevels.vue')
	},
	{
		path: '/my-profile/',
		name: 'MyProfile',
		component: () =>
			import(/* webpackChunkName: "my-profile" */ '../views/MyProfile.vue')
	},
	{
		path: '/user-levelsets/',
		name: 'LevelSets',
		component: () =>
			import(
				/* webpackChunkName: "users-levelsets" */ '../views/PublicLevelsets.vue'
			)
	}
];

const router = new VueRouter({
	routes
});
/*
let isFirstTime = true;
router.beforeEach(async (to, from, next) => {
	if (isFirstTime) {
		await checkAuth();
		isFirstTime = false;
	}
	next();
});

async function checkAuth() {
	try {
		let res = await fetch(`/api/v1/public/checkAuth`);
		let data = await res.json();
		if (data && data.status == 'ok') {
			// logged in
			store.state.userData = data.userData;
			return true;
		}
	} catch (e) {
		console.log(e);
	}
}
*/
export default router;
