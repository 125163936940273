<template>

	<div class="chatbox">

		<div class="bg-light p-2" >

			<div v-if="!$store.state.userData" class="alert alert-primary">
				<a class="alert-link" href="#" @click.prevent="$bvModal.show('login-modal')">Login</a> or <a class="alert-link" href="#" @click.prevent="$bvModal.show('register-modal')">Register</a> to comment
			</div>

			<div v-if="$store.state.userData">
				<div class="row">
					<div class="col-1 text-left fs-12">
						Name:
					</div>
					<div class="col-4">
						<input
							type="text"
							class="form-control"
							disabled
							v-model="userName"
						/>
						<!-- @input="storeName($event)" -->
					</div>
				</div>

				<div class="row mt-1">
					<div class="col-1 text-left fs-12">
						Comment:
					</div>
					<div class="col-11">
						<textarea
							class="form-control shadow-none textarea"
							v-model="comment"
						></textarea>
					</div>
				</div>

				<div class="mt-2 text-right">
					<button
						class="btn btn-primary btn-sm shadow-none"
						type="button"
						:disabled="!$store.state.userData || !userName || !comment"
						@click="postComment()"
					>
						OK
					</button>
				</div>
			</div>

			<div class="container mt-2 text-left comments-container card">
				<div v-for="comment in comments" :key="comment.id" class="comment mt-2">
					<div class="row">
						<div class="name col-12">
							{{ comment.name }}
							<span class="badge badge-light date">{{ comment.created }}</span>
						</div>
						<div class="comment-text col-12">
							{{ comment.comment }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.comments-container {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.comment {
	border-bottom: 1px solid #f0f0f0;
}

.date {
	font-size: 11px;
}
.comment-text {
	font-size: 12px;
}
.fs-12 {
	font-size: 12px;
}
.shadow-none {
	box-shadow: none;
}
.name {
	color: #007bff;
	font-size: 11px;
}
.cursor:hover {
	color: blue;
}
.cursor {
	cursor: pointer;
}
.textarea {
	resize: none;
}
</style>

<script>
// Example POST method implementation:
async function postData(url = '', data = {}) {
	// Default options are marked with *
	const response = await fetch(url, {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json'
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		redirect: 'error', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	return response.json(); // parses JSON response into native JavaScript objects
}

// @ is an alias to /src
export default {
	name: 'ChatBox',

	props: {
		levelId: {
			type: Number
		}
	},

	data: function() {
		return {
			comments: [],
			page: 1,
			isLoading: false,
			userName: '',
			comment: ''
		};
	},

	watch: {
		'$store.state.userData': function(userData) {
			this.userName = userData?.name || '';
		}
	},

	computed: {},

	mounted: function() {
		this.userName = this.$store.state.userData?.name;

		this.page = 1;
		this.loadComments();
		let $ = window.$;
		let that = this;
		$('.comments-container').on('scroll', function() {
			if (
				$(this).scrollTop() + $(this).innerHeight() >=
				$(this)[0].scrollHeight - 10
			) {
				if (!that.loading) {
					that.page++;
					that.loadComments();
				}
			}
		});
	},

	beforeDestroy: function() {
		let $ = window.$;
		$('.comments-container').off('scroll');
	},

	methods: {
		// storeName(event) {
		// 	let name = event.target.value;
		// 	localStorage.setItem('userName', name);
		// },

		getName() {
			return this.$store.state.userData?.name || '';
		},

		loadComments() {
			let levelId = this.levelId || '';
			let page = this.page;
			console.log('loading comments ' + levelId + ' - ' + page);
			this.isLoading = true;
			fetch(`/api/v1/comments/${levelId}?page=${page}`)
				.then(data => data.json())
				.then(data => {
					this.comments.push(...data.commentsData);
					if (!data.commentsData.length) {
						this.page--;
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		async postComment() {
			let userName = this.userName;
			let comment = this.comment;
			let levelId = this.levelId;
			if (!userName || !comment) {
				return;
			}
			await postData('/api/v1/comments', {
				userName,
				comment,
				levelId
			});
			this.comment = '';
			this.page = 1;
			this.comments = [];
			this.loadComments();
		}
	}
};
</script>
