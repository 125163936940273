<template>
	<div class="level-container">
		<div class="lvl-name">{{ levelName }}</div>
		<!-- <div class="niepokoj">robbo.niepokoj.com</div> -->
		<canvas
			width="192px"
			height="372px"
			style="background-color: cadetblue;"
			class="game-canvas1"
		></canvas>
		<canvas
			width="192px"
			height="372px"
			style="background-color: cadetblue;"
			class="game-canvas2"
		></canvas>
	</div>
</template>

<style lang="scss">
.level-container {
	position: relative;
	display: inline-block;
	margin-right: 5px;

	.lvl-name {
		position: absolute;
		left: 4px;
		top: -2px;
		color: white;
		font-size: 12px;
		text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
	}

	.niepokoj {
		position: absolute;
		left: 0;
		top: 0;
		color: white;
		font-size: 12px;
		text-shadow: 0 0 2px black;
		transform: rotateZ(90deg);
		transform-origin: left bottom;
	}
}

.level-container.set2 .game-canvas1 {
	display: none;
}
.level-container:not(.set2) .game-canvas2 {
	display: none;
}
</style>
<script>
import levelUtils from './../utils/levelBlocks';
let id = 0;

export default {
	name: 'LevelView',
	props: {
		levelData: void 0,
		levelName: {
			type: String,
			default: 'Level name'
		},
		tileWidth: {
			type: Number,
			default: 32
		}
	},
	mounted: async function() {
		this.canvas1 = await this.createBackground(
			'/robbo/gfx/tiles-transparent.png'
		); //, `.level-container.id-${this.id} .tile`);
		this.canvas2 = await this.createBackground('/img/tiles-transparent2.png'); //, `.level-container.id-${this.id}.set2 .tile`);
		this.testLevel = this.levelData;
		this.drawLevelCanvas(this.canvas1, this.$el.querySelector('.game-canvas1'));
		this.drawLevelCanvas(this.canvas2, this.$el.querySelector('.game-canvas2'));
		this.intervalhHandle = setInterval(() => {
			this.animateBlocks();
		}, 500);
	},
	beforeDestroy() {
		clearInterval(this.intervalhHandle);
	},
	components: {
		// HelloWorld
	},
	data: function() {
		return {
			id: ++id,
			canvas1: void 0,
			canvas2: void 0,
			intervalhHandle: void 0,
			tileSetFlag: false,
			currentLevelWidth: 16,
			currentLevelHeight: 31,
			name: '',
			testLevel:
				'3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,3,3,4,0,0,0,0,0,0,0,0,0,0,1,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,0,6,0,0,0,0,0,6,0,39,29,12,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,12,28,40,0,0,6,0,0,0,6,0,0,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,0,0,0,0,0,6,0,0,0,39,29,12,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,12,28,40,0,0,0,0,6,0,0,0,0,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,0,0,6,0,0,0,6,0,0,39,29,12,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,12,28,40,0,6,0,0,0,0,0,6,0,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,5,0,0,0,0,0,0,0,0,0,0,4,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3'
		};
	},
	computed: {
		showAlert() {
			return this.name.length > 4 ? true : false;
		}
	},
	methods: {
		createBackground(url) {
			const TILES_PER_ROW = 8; // image tiles per row
			const TILES_PER_COLUMN = 9; // image tiles per row
			const size = this.tileWidth;

			return new Promise(resolve => {
				let img = new Image();
				img.onload = () => {
					let canvas = document.createElement('canvas');
					let ctx = canvas.getContext('2d');
					canvas.width = size * TILES_PER_ROW;
					canvas.height = size * TILES_PER_COLUMN;
					ctx.drawImage(
						img,
						0,
						0,
						img.width,
						img.height,
						0,
						0,
						canvas.width,
						canvas.height
					);
					// let cls = this.css_getclass(cssCls);
					// cls.style.backgroundImage = 'url(' + canvas.toDataURL() + ')';
					resolve(canvas);
				};
				img.src = url;
			});
		},

		animateBlocks() {
			let $el = this.$el; //.querySelector(".level-container");
			$el.className =
				`level-container id-${this.id} ` + (this.tileSetFlag ? 'set2' : '');
			this.tileSetFlag = !this.tileSetFlag;
		},

		drawLevelCanvas(srcCanvas, dstCanvas) {
			const TILE_WIDTH = this.tileWidth;
			const TILE_HEIGHT = this.tileWidth;
			const TILES_PER_ROW = 8; // image tiles per row
			const size = this.tileWidth;
			let ctx = dstCanvas.getContext('2d');
			dstCanvas.width = this.currentLevelWidth * this.tileWidth;
			dstCanvas.height = this.currentLevelHeight * this.tileWidth;

			let currentLevelData =
				this.testLevel.split(',') ||
				new Array(this.currentLevelWidth * this.currentLevelHeight).fill(0);

			for (var y = 0; y < this.currentLevelHeight; y++) {
				for (var x = 0; x < this.currentLevelWidth; x++) {
					let tileNr = +currentLevelData[x + y * this.currentLevelWidth] & 127;
					if (tileNr == 0) continue;

					// tileNr = levelUtils.currentBlocksImgIndex[tileNr];
					tileNr = levelUtils.currentBlocksImgIndex[tileNr];
					// var tnx = tn % TILES_PER_ROW;
					// var tny = Math.floor(tn / TILES_PER_ROW);

					let ox = tileNr % TILES_PER_ROW;
					let oy = Math.floor(tileNr / TILES_PER_ROW);

					ctx.drawImage(
						srcCanvas,
						ox * size,
						oy * size,
						size,
						size,
						x * TILE_WIDTH,
						y * TILE_HEIGHT,
						TILE_WIDTH,
						TILE_HEIGHT
					);
				}
			}
		}
	}
};
</script>
