<template>
	<div id="app">
		<div class="header">
			<div class="container">
				<div class="row p-3">
					<div class="col-sm text-left">
						<router-link to="/" class="top-link">
							<h3><img src="/img/robbo-logo.gif" alt="robbo" /> ROBBO</h3>
						</router-link>
					</div>
					<div class="col-sm"></div>
				</div>
				<div class="row">
					<div class="col-8">
						<ul class="nav nav2">
							<li class="nav-items">
								<router-link to="/" class="nav-link">
									Home
								</router-link>
							</li>
							<li class="nav-items">
								<router-link to="/user-levels" class="nav-link">
									User Levels
								</router-link>
							</li>
							<li class="nav-items">
								<router-link to="/user-levelsets" class="nav-link disabled">
									User Level sets
								</router-link>
							</li>
							<li class="nav-items">
								<router-link to="/about" class="nav-link disabled">
									Game Rules
								</router-link>
							</li>
							<li class="nav-items">
								<router-link to="/editor" class="nav-link">
									Game Editor
								</router-link>
							</li>
							<li class="nav-items">
								<router-link to="/about" class="nav-link disabled">
									Contact
								</router-link>
							</li>
						</ul>
					</div>
					<div class="col-4">
						<ul class="nav nav2 justify-content-end">
							<li class="nav-items" v-if="!userData">
								<a class="nav-link nav-link-sm" href="#" @click.prevent="$bvModal.show('login-modal')">Login</a>
							</li>
							<li class="nav-items" v-if="userData">
								<a class="nav-link nav-link-sm" href="#" @click.prevent="logout">Logout {{userData.name}}</a>
							</li>
							<li class="nav-items" v-if="userData">
								<router-link to="/my-levels" class="nav-link nav-link-sm">
									My levels
								</router-link>
							</li>
							<li class="nav-items" v-if="userData">
								<router-link to="/my-profile" class="nav-link nav-link-sm">
									My profile
								</router-link>
							</li>
							<li class="nav-items" v-if="!userData">
								<!-- <router-link to="/register" class="nav-link nav-link-sm disabled">Register</router-link> -->
								<a class="nav-link nav-link-sm" href="#" @click.prevent="$bvModal.show('register-modal')">Register</a>
							</li>
							<li class="nav-items" v-if="!userData">
								<a class="nav-link nav-link-sm" href="#" @click.prevent="$bvModal.show('reset-modal')">Reset password</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="p-1">
			<router-view />
		</div>
		<footer>
			<div class="container">
				<div class="text-right footer p-3">
					© {{ new Date().getFullYear() }} - Niepokoj.com
				</div>
			</div>
		</footer>

		<b-modal
			id="reset-modal"
			@show="authError = false"
			@shown="$refs.email2.focus()"
			@ok="doReset"
			ref="resetModal"
			:ok-disabled="!validateEmail(email) || isBusy"
		>
			<template #modal-title> Reset </template>
			<form>
				<div class="form-group">
					<label>Enter your email address</label>
					<input
						ref="email2"
						type="email"
						autocomplete="email"
						class="form-control"
						v-model="email"
					/>
				</div>
				<p>If you enter your email address you should get email with reset link.</p>
				<div class="alert alert-danger" v-if="authError">
					{{ authError }}
				</div>
			</form>
		</b-modal>

		<b-modal
			id="login-modal"
			@show="authError = false"
			@shown="$refs.email.focus()"
			@ok="doAuth"
			ref="loginModal"
			:ok-disabled="!isLoginFormValid || isBusy"
		>
			<template #modal-title> Login </template>

			<form>
				<div class="form-group">
					<label>Email</label>
					<input
						ref="email"
						type="email"
						class="form-control"
						v-model="email"
					/>
				</div>
				<div class="form-group">
					<label>Password</label>
					<input type="password" class="form-control" v-model="pass" />
				</div>
				<div class="alert alert-danger" v-if="authError">
					{{ authError }}
				</div>
			</form>
		</b-modal>

		<b-modal
			id="register-modal"
			@show="authError = false"
			@shown="$refs.regName.focus()"
			@ok="doRegister"
			ref="registerModal"
			:ok-disabled="!isRegFormValid || isBusy"
		>
			<template #modal-title> Register </template>

			<form>
				<div class="form-group">
					<label>Name</label>
					<input
						ref="regName"
						type="text"
						class="form-control"
						v-model="regName"
					/>
				</div>
				<div class="form-group">
					<label>Email</label>
					<input type="email" class="form-control" v-model="regEmail" />
				</div>
				<div class="form-group">
					<label>Password</label>
					<input
						type="password"
						class="form-control"
						v-model="password"
						@blur="validatePassword"
						:class="{ 'is-invalid': !passwordValid }"
					/>
					<div class="invalid-feedback" v-if="passwordNotEqual">
						Password must be minimum 8 chars with numbers
					</div>
				</div>
				<div class="form-group">
					<label>Repeat password</label>
					<input
						type="password"
						class="form-control"
						v-model="password2"
						@blur="checkPasswordsEqual"
						:class="{ 'is-invalid': passwordNotEqual }"
					/>
					<div class="invalid-feedback" v-if="passwordNotEqual">
						Passwords not equal
					</div>
				</div>
				<div class="form-group">
					<label>Language</label>
					<b-form-select v-model="language" :options="langs"></b-form-select>
				</div>
				<div class="alert alert-danger" v-if="authError">
					{{ authError }}
				</div>
			</form>
		</b-modal>

		<b-modal
			id="reset-pass-modal"
			@show="authError = false"
			@shown="$refs.resPass.focus()"
			@ok="doResetPass"
			ref="resetPassModal"
			:ok-disabled="!isResetFormValid || isBusy"
		>
			<template #modal-title> Reset password </template>

			<form>
				<div class="form-group">
					<label>New password</label>
					<input
						ref="resPass"
						type="password"
						class="form-control"
						v-model="password"
						@blur="validatePassword"
						:class="{ 'is-invalid': !passwordValid }"
					/>
					<div class="invalid-feedback" v-if="passwordNotEqual">
						Password must be minimum 8 chars with numbers
					</div>
				</div>
				<div class="form-group">
					<label>Repeat new password</label>
					<input
						type="password"
						class="form-control"
						v-model="password2"
						@blur="checkPasswordsEqual"
						:class="{ 'is-invalid': passwordNotEqual }"
					/>
					<div class="invalid-feedback" v-if="passwordNotEqual">
						Passwords not equal
					</div>
				</div>
				<div class="alert alert-danger" v-if="authError">
					{{ authError }}
				</div>
			</form>
		</b-modal>


	</div>
</template>

<script>
export default {

	computed: {
		isLoginFormValid() {
			return !!(this.email && this.pass);
		},
		isResetFormValid() {
			return !!(this.password && this.password == this.password2);
		},
		isRegFormValid() {
			return !!(
				this.regEmail &&
				this.password &&
				this.password == this.password2
			);
		},
		userData() {
			return this.$store.state.userData;
		}
	},

	data: function() {
		return {
			email: '',
			pass: '',
			isBusy: false,
			authError: false,

			regEmail: '',
			regName: '',
			password: '',
			password2: '',
			language: 'pl',

			passwordValid: true,
			passwordNotEqual: false,

			langs: [
				{ value: 'pl', text: 'Polski' },
				{ value: 'en', text: 'English' }
			]
		};
	},

	mounted() {
		this.init();
	},

	methods: {

		init() {
			this.checkAuth();
		},

		validatePassword() {
			this.passwordValid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(
				this.password
			);
			this.checkPasswordsEqual();
		},
		checkPasswordsEqual() {
			this.passwordNotEqual = !(this.password == this.password2);
		},

		async checkAuth() {
			this.isBusy = true;
			try {
				let res = await fetch(`/api/v1/public/checkAuth`);
				let data = await res.json();
				if (data && data.status == 'ok') {
					// logged in
					this.setUser(data.userData);
				}
			} catch (e) {
				console.log(e);
			}
			this.isBusy = false;
		},

		async logout() {
			let res = await fetch(`/api/v1/public/logout`);
			let data = await res.json();
			if (data && data.status == 'ok') {
				// logged in
				this.setUser();
				this.$router.push('/');
			}
		},

		async doResetPass(bvModalEvt) {

			bvModalEvt.preventDefault();

			let hash = this.$route.params.hash;
			if (!hash || !this.$route.meta?.reset) {
				return;
			}

			this.isBusy = true;
			this.authError = false;
			try {
				let options = {
					method: 'POST',
					cache: 'no-cache',
					redirect: 'error',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						password: btoa(this.password),
						hash
					})
				};
				let res = await fetch(`/api/v1/public/reset-end`, options);
				let data = await res.json();
				if (data && data.status == 'ok') {
					this.$refs.resetPassModal.hide();
					this.$refs.loginModal.show();
					this.$bvModal.msgBoxOk('Password reset succeed. You can login now.');
				} else {
					this.authError = data.message || 'Unexpected error';
					this.pass = '';
				}
			} catch (e) {
				this.authError = 'Unexpected error';
			}
			this.isBusy = false;
		},

		async doReset(bvModalEvt) {
			bvModalEvt.preventDefault();

			this.isBusy = true;
			this.authError = false;
			try {
				let options = {
					method: 'POST',
					cache: 'no-cache',
					redirect: 'error',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: this.email
					})
				};
				let res = await fetch(`/api/v1/public/reset-start`, options);
				let data = await res.json();
				if (data && data.status == 'ok') {
					this.$refs.resetModal.hide();
				} else {
					this.authError = data.message || 'Unexpected error';
					this.pass = '';
				}
			} catch (e) {
				this.authError = 'Unexpected error';
			}
			this.isBusy = false;
		},

		async doAuth(bvModalEvt) {

			bvModalEvt.preventDefault();

			this.isBusy = true;
			this.authError = false;
			try {
				let options = {
					method: 'POST',
					cache: 'no-cache',
					redirect: 'error',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: this.email,
						password: btoa(this.pass)
					})
				};
				let res = await fetch(`/api/v1/public/login`, options);
				let data = await res.json();
				if (data && data.status == 'ok') {
					// logged in
					this.setUser(data.userData);
					this.$refs.loginModal.hide();
				} else {
					this.authError = data.message || 'Unexpected error';
					this.pass = '';
				}
			} catch (e) {
				this.authError = 'Unexpected error';
			}
			this.isBusy = false;
		},

		async doRegister(bvModalEvt) {

			bvModalEvt.preventDefault();

			this.isBusy = true;
			this.authError = false;
			try {
				let options = {
					method: 'POST',
					cache: 'no-cache',
					redirect: 'error',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: this.regEmail,
						name: this.regName,
						password: btoa(this.password),
						password2: btoa(this.password2),
						language: this.language
					})
				};
				let res = await fetch(`/api/v1/public/register`, options);
				let data = await res.json();
				if (data && data.status == 'ok') {
					// registered
					this.$refs.registerModal.hide();
				} else {
					this.authError = data.message || 'Unexpected error';
				}
			} catch (e) {
				this.authError = 'Unexpected error';
			}
			this.isBusy = false;
		},

		setUser(userData) {
			this.$store.state.userData = userData;
		},

		validateEmail(email) {
			return String(email)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
		}
	}
}
</script>

<style lang="scss" scoped>
body {
	background: #003d4c;
	color: white;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.header,
footer {
	background: #2c3e50;
	color: white;
}

.top-link {
	color: white;
	text-decoration: none;
}

.nav2 {
	padding: 5px;

	a {
		font-weight: bold;
		color: white;
		font-size: 1.2em;

		&.nav-link-sm {
			font-size: inherit;
		}

		&.router-link-exact-active {
			color: #42b983;
		}
		&:hover {
			color: darkseagreen;
		}

		&.disabled {
			color: gray;
		}
	}
}
</style>
