import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isLogged: false,
    userData: void 0
	},
	mutations: {},
	actions: {},
	modules: {}
});
