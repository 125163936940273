/*  eslint-disable */
// LET BITS MEANS SOMETHING
const B_ROBBO 		    = 1;    // ROBBO
const B_CANT_KILL	    = 2;    // NIE MOŻE ZGINĄĆ OD STRZAŁU
const B_CANT_BOOM	    = 4;    // NIE MOŻE ZGINĄĆ OD WYBUCHU
const B_CAN_PUSH		= 8;    // MOŻNA PCHAĆ
const B_BIRD			= 16;   // PTAK
const B_SMTHG       	= 32;   // ZNACZNIK UZYWANY DO RÓŻNYCH CELÓW
const B_PROJECTILE  	= 64;   // LECĄCY POCISK
const B_BOX_SLIDE	    = 128;	// ŚLIZGAJĄCA KRATKA
const B_CANNON   	    = 256;  // DZIAŁKO
const B_MON_RIGHT 	= 512;  // POTWOREK PRAWOSTRONNY
const B_MON_LEFT  	= 1024; // POTWOREK LEWOSTRONNY
const B_MIRROR    	= 2048; // LUSTRO
const B_CANT_KBOM	    = 2 | 4; //B_CANT_KILL|B_CANT_BOOM,  // ROBBO

const STOP_BIT		= 1073741824; // = 2^30


// BLOCK INDEX IN LEVEL
const CHAR_ROBBO_LEFT_A       = 1;
const CHAR_ROBBO_LEFT_B       = 80;
const CHAR_ROBBO_RIGHT_A      = 82;
const CHAR_ROBBO_RIGHT_B      = 84;
const CHAR_ROBBO_UP_A         = 86;
const CHAR_ROBBO_UP_B         = 87;
const CHAR_ROBBO_DOWN_A       = 89;
const CHAR_ROBBO_DOWN_B       = 91;

const CHAR_BOX			    = 2;
const CHAR_WALL 			    = 3;
const CHAR_SCREW       	    = 4;
const CHAR_SHIP_A     	    = 5;
const CHAR_SHIP_BLINK         = 104;
const CHAR_BULLET             = 6;
const CHAR_KEY			    = 12;
const CHAR_DOOR			    = 13;
const CHAR_GROUND			    = 14;

const CHAR_BIRD_LEFT_A        = 7;
const CHAR_BIRD_RIGHT_A       = 8;
const CHAR_BIRD_DOWN_A        = 10;
const CHAR_BIRD_UP_A          = 11;

const CHAR_BOOM_1             = 9;
const CHAR_BOOM_2             = 94;
const CHAR_BOOM_3             = 95;
const CHAR_BOOM_4             = 96;
const CHAR_BOOM_5             = 97;
const CHAR_BOOM_6             = 98;
const CHAR_BOOM_7             = 99;

const CHAR_PROJECTILE_RIGHT_A	= 15;
const CHAR_PROJECTILE_LEFT_A 	= 16;
const CHAR_PROJECTILE_UP_A   	= 17;
const CHAR_PROJECTILE_DOWN_A 	= 18;

const CHAR_SLIDE_BOX          = 19;
const CHAR_SLIDE_BOX_LEFT     = 76;
const CHAR_SLIDE_BOX_RIGHT    = 77;
const CHAR_SLIDE_BOX_UP       = 78;
const CHAR_SLIDE_BOX_DOWN     = 79;

const CHAR_BOMBA              = 20;
const CHAR_BOMBA2             = 93;

const CHAR_CANNON_LEFT        = 21;
const CHAR_CANNON_RIGHT       = 22;
const CHAR_CANNON_UP          = 23;
const CHAR_CANNON_DOWN        = 24;

const CHAR_MR_RIGHT_A         = 25;
const CHAR_MR_UP_A            = 81;
const CHAR_MR_LEFT_A          = 83;
const CHAR_MR_DOWN_A          = 85;

const CHAR_ML_RIGHT_A         = 26;
const CHAR_ML_UP_A            = 88;
const CHAR_ML_LEFT_A          = 90;
const CHAR_ML_DOWN_A          = 92;

const CHAR_SURPRISE		    = 27;
const CHAR_SURPRISE_BOOM1	    = 70;
const CHAR_SURPRISE_BOOM2	    = 71;
const CHAR_SURPRISE_BOOM3	    = 72;

const CHAR_MAGNEZ_LEFT	    = 28;
const CHAR_MAGNEZ_RIGHT	    = 29;
const CHAR_MAG_ROBBO_L	    = 73;
const CHAR_MAG_ROBBO_R	    = 74;

const CHAR_NIC			    = 30;

const CHAR_FIRE_BIRD_LEFT_A	= 31;
const CHAR_FIRE_BIRD_RIGHT_A	= 32;

const CHAR_ROTATE_CANNON_RIGHT = 33;
const CHAR_ROTATE_CANNON_LEFT  = 60;
const CHAR_ROTATE_CANNON_UP    = 61;
const CHAR_ROTATE_CANNON_DOWN  = 62;
const CHAR_MOVING_CANNON_L_A   = 34;
const CHAR_MOVING_CANNON_R_A   = 63;

const CHAR_LONG_CANNON_LEFT   = 35;
const CHAR_LONG_CANNON_RIGHT  = 36;
const CHAR_LONG_CANNON_UP     = 37;
const CHAR_LONG_CANNON_DOWN   = 38;

const CHAR_LONG_PROJECTILE_RIGHT_A = 50;
const CHAR_LONG_PROJECTILE_RIGHT_B = 51;
const CHAR_LONG_PROJECTILE_LEFT_A  = 52;
const CHAR_LONG_PROJECTILE_LEFT_B  = 53;
const CHAR_LONG_PROJECTILE_UP_A    = 54;
const CHAR_LONG_PROJECTILE_UP_B    = 55;
const CHAR_LONG_PROJECTILE_DOWN_A  = 56;
const CHAR_LONG_PROJECTILE_DOWN_B  = 57;

const CHAR_BLAST_CANNON_LEFT      = 39;
const CHAR_BLAST_CANNON_RIGHT     = 40;
const CHAR_BLAST_CANNON_UP        = 41;
const CHAR_BLAST_CANNON_DOWN      = 42;

const CHAR_BLAST_PROJECTILE_RIGHT  = 58;
const CHAR_BLAST_PROJECTILE_LEFT   = 59;
const CHAR_BLAST_PROJECTILE_UP     = 68;
const CHAR_BLAST_PROJECTILE_DOWN   = 75;

const CHAR_MIRROR_1_A             = 43;
const CHAR_MIRROR_2_A             = 44;
const CHAR_MIRROR_3_A             = 45;
const CHAR_MIRROR_4_A             = 46;
const CHAR_MIRROR_5_A             = 47;
const CHAR_MIRROR_6_A             = 48;
const CHAR_MIRROR_7_A             = 64;
const CHAR_MIRROR_8_A             = 65;
const CHAR_MIRROR_9_A             = 66;
const CHAR_MIRROR_10_A            = 67;
const CHAR_MOVING_EYES_A          = 69;
const CHAR_ADD_LIVE               = 49;

const CHAR_TELE_BOOM1             = 100;
const CHAR_TELE_BOOM2             = 101;
const CHAR_TELE_BOOM3             = 102;
const CHAR_TELE_BOOM4             = 103;


// TILE PLACE ON TILE SET
const i_EMPTY                     = 0;
const i_BOX    	                = 1;
const i_WALL                      = 2;
const i_ELEMENT                   = 3;
const i_SHIP_A                    = 4;
const i_BULLET                    = 5;
const i_BIRD_A                    = 6;
const i_BIRD_B                    = 7;
const i_GROUND                    = 8;
const i_SHIP_B                    = 9;
const i_ROBBO_UP_A                = 10;
const i_ROBBO_UP_B                = 11;
const i_ROBBO_RIGHT_A             = 12;
const i_ROBBO_RIGHT_B             = 13;
const i_ROBBO_LEFT_A              = 14;
const i_ROBBO_LEFT_B              = 15;
const i_ROBBO_DOWN_A              = 16;
const i_ROBBO_DOWN_B              = 17;
const i_BIRD_FIRE_A               = 18;
const i_BIRD_FIRE_B               = 19;
const i_PROJECTILE_UP_A           = 20;
const i_PROJECTILE_UP_B           = 21;
const i_PROJECTILE_RIGHT_A        = 22;
const i_PROJECTILE_RIGHT_B        = 23;
const i_PROJECTILE_LEFT_A         = 24;
const i_PROJECTILE_LEFT_B         = 25;
const i_PROJECTILE_DOWN_A         = 26;
const i_PROJECTILE_DOWN_B         = 27;
const i_EYES_A                    = 28;
const i_EYES_B                    = 29;
const i_SURPRISE                  = 30;
const i_BLACK                     = 31;
const i_M_RIGHT_A                 = 32;
const i_M_RIGHT_B                 = 33;
const i_M_LEFT_A                  = 34;
const i_M_LEFT_B                  = 35;
const i_MAGNEZ_RIGHT              = 36;
const i_MAGNEZ_LEFT               = 37;
const i_MIRROR_A                  = 38;
const i_MIRROR_B                  = 39;
const i_KEY                       = 40;
const i_CANNON_UP                 = 41;
const i_CANNON_RIGHT              = 42;
const i_CANNON_MOVE               = 43;
const i_CANNON_LEFT               = 44;
const i_CANNON_DOWN               = 45;
const i_DOOR                      = 46;
const i_BOOM_4                    = 47;
const i_BOOM_3                    = 48;
const i_BOOM_2                    = 49;
const i_BOOM_1                    = 50;
const i_BOMB                      = 51;
const i_LIVE                      = 52;
const i_SLIDE_BOX                 = 53;
// const i_WALL2					    = 56;

let blocksMask              = [];
let blocksImages            = [];   // initial array for current Blocks img index = image index, block index
let swapImages              = [];   // swap img array = block index, (img index 1, img index 2)
let currentBlocksImgIndex   = [];   // for every block is keeped image index, currentBlocksImgIndex[block] = imageIndex;

function init() {
	
	blocksMask = [
		0,                                      // 0)
		B_ROBBO,                           // 1) (A) CHAR_ROBBO_LEFT_A
		B_CAN_PUSH|B_CANT_KILL,       // 2) (B) CHAR_KRATKA
		B_CANT_KBOM,                       // 3) (C) CHAR_SCIANA
		B_CANT_KILL,                       // 4) (D) CHAR_SROBKA
		B_CANT_KBOM|B_CAN_PUSH,       // 5) (E) CHAR_STATEK
		B_SMTHG,                           // 6) (F) CHAR_NABOJ
		B_BIRD,                            // 7) (G) CHAR_PTAK_LEFT_A
		B_BIRD,                            // 8) (H) CHAR_PTAK_RIGHT_A
		B_CANT_KILL,                       // 9) (I) CHAR_BOOM1
		B_BIRD,                            //10) (J) CHAR_PTAK_DOWN_A
		B_BIRD,                            //11) (K) CHAR_PTAK_UP_A
		B_CANT_KILL,                       //12) (L) CHAR_KLUCZ
		B_CANT_KILL,                       //13) (M) CHAR_DRZWI
		B_SMTHG,                           //14) (N) CHAR_ZIEMIA
		B_CANT_KBOM|B_PROJECTILE,     //15) (O) CHAR_POCISK_RIGHT_A
		B_CANT_KBOM|B_PROJECTILE,     //16) (P) CHAR_POCISK_LEFT_A
		B_CANT_KBOM|B_PROJECTILE,     //17) (Q) CHAR_POCISK_UP_A
		B_CANT_KBOM|B_PROJECTILE,     //18) (R) CHAR_POCISK_DOWN_A
		B_CANT_KILL|B_CAN_PUSH,       //19) (S) CHAR_KRATKA_SLIZG
		B_CAN_PUSH,                        //20) (T) CHAR_BOMBA
		B_CANT_KILL|B_CANNON,         //21) (U) CHAR_DZIALKO_LEFT
		B_CANT_KILL|B_CANNON,         //22) (V) CHAR_DZIALKO_RIGHT
		B_CANT_KILL|B_CANNON,         //23) (W) CHAR_DZIALKO_UP
		B_CANT_KILL|B_CANNON,         //24) (X) CHAR_DZIALKO_DOWN
		B_MON_RIGHT,                       //25) (Y) CHAR_MR_RIGHT_A
		B_MON_LEFT,                        //26) (Z) CHAR_ML_RIGHT_A
		B_CAN_PUSH,                        //27) ([) CHAR_NIESPODZIANKA
		B_CANT_KBOM,                       //28) (\) CHAR_MAGNEZ_LEFT
		B_CANT_KBOM,                       //29) (]) CHAR_MAGNEZ_RIGHT
		B_CANT_KBOM,                       //30) (^) CHAR_NIC
		B_BIRD,                            //31) (_) CHAR_PTAK_FIRE_LEFT_A
		B_BIRD,                            //32) (`) CHAR_PTAK_FIRE_RIGHT_A
		B_CANT_KILL|B_CANNON,         //33) (a) CHAR_DZIALO_OBROT_RIGHT
		B_CANT_KILL|B_CANNON|B_CAN_PUSH,//34) (b) CHAR_DZIALKO_MOVE_L_A
		B_CANT_KILL|B_CANNON,         //35) (c) CHAR_DZIALKO_CIAG_LEFT
		B_CANT_KILL|B_CANNON,         //36) (d) CHAR_DZIALKO_CIAG_RIGHT
		B_CANT_KILL|B_CANNON,         //37) (e) CHAR_DZIALKO_CIAG_UP
		B_CANT_KILL|B_CANNON,         //38) (f) CHAR_DZIALKO_CIAG_DOWN
		B_CANT_KILL|B_CANNON,         //39) (g) CHAR_DZIALKO_BLAST_LEFT
		B_CANT_KILL|B_CANNON,         //40) (h) CHAR_DZIALKO_BLAST_RIGHT
		B_CANT_KILL|B_CANNON,         //41) (i) CHAR_DZIALKO_BLAST_UP
		B_CANT_KILL|B_CANNON,         //42) (j) CHAR_DZIALKO_BLAST_DOWN
		B_CANT_KILL|B_MIRROR,         //43) (k) CHAR_LUSTRO_1_A
		B_CANT_KILL|B_MIRROR,         //44) (l) CHAR_LUSTRO_2_A
		B_CANT_KILL|B_MIRROR,         //45) (m) CHAR_LUSTRO_3_A
		B_CANT_KILL|B_MIRROR,         //46) (n) CHAR_LUSTRO_4_A
		B_CANT_KILL|B_MIRROR,         //47) (o) CHAR_LUSTRO_5_A
		B_CANT_KILL|B_MIRROR,         //48) (p) CHAR_LUSTRO_6_A
		B_SMTHG,                           //49) (q) CHAR_ZYCIE
		B_CANT_KBOM|B_PROJECTILE,     //50) CHAR_POCISK_CIAGLY_RIGHT_A
		B_CANT_KBOM|B_PROJECTILE,     //51) CHAR_POCISK_CIAGLY_RIGHT_B
		B_CANT_KBOM|B_PROJECTILE,     //52) CHAR_POCISK_CIAGLY_LEFT_A
		B_CANT_KBOM|B_PROJECTILE,     //53) CHAR_POCISK_CIAGLY_LEFT_B
		B_CANT_KBOM|B_PROJECTILE,     //54) CHAR_POCISK_CIAGLY_UP_A
		B_CANT_KBOM|B_PROJECTILE,     //55) CHAR_POCISK_CIAGLY_UP_B
		B_CANT_KBOM|B_PROJECTILE,     //56) CHAR_POCISK_CIAGLY_DOWN_A
		B_CANT_KBOM|B_PROJECTILE,     //57) CHAR_POCISK_CIAGLY_DOWN_B
		B_CANT_KBOM|B_PROJECTILE,     //58) CHAR_POCISK_BLAST_RIGHT
		B_CANT_KBOM|B_PROJECTILE,     //59) CHAR_POCISK_BLAST_LEFT
		B_CANT_KILL|B_CANNON,         //60) CHAR_DZIALO_OBROT_LEFT
		B_CANT_KILL|B_CANNON,         //61) CHAR_DZIALO_OBROT_UP
		B_CANT_KILL|B_CANNON,         //62) CHAR_DZIALO_OBROT_DOWN
		B_CANT_KILL|B_CANNON|B_CAN_PUSH,//63) CHAR_DZIALKO_MOVE_R_A
		B_CANT_KILL|B_MIRROR,         //64) CHAR_LUSTRO_7_A
		B_CANT_KILL|B_MIRROR,         //65) CHAR_LUSTRO_8_A
		B_CANT_KILL|B_MIRROR,         //66) CHAR_LUSTRO_9_A
		B_CANT_KILL|B_MIRROR,         //67) CHAR_LUSTRO_10_A
		B_CANT_KBOM|B_PROJECTILE,     //68) CHAR_POCISK_BLAST_UP
		B_SMTHG,                           //69) CHAR_OCZY_A
		B_CANT_KBOM,                       //70) CHAR_NIESP_BOOM1
		B_CANT_KBOM,                       //71) CHAR_NIESP_BOOM2
		B_CANT_KBOM,                       //72) CHAR_NIESP_BOOM3
		B_ROBBO|B_SMTHG,              //73) CHAR_MAG_ROBBO_L
		B_ROBBO|B_SMTHG,              //74) CHAR_MAG_ROBBO_R
		B_CANT_KBOM|B_PROJECTILE,     //75) CHAR_POCISK_BLAST_DOWN
		B_CANT_KILL|B_BOX_SLIDE,      //76) CHAR_KRATKA_SLIZG_LEFT
		B_CANT_KILL|B_BOX_SLIDE,      //77) CHAR_KRATKA_SLIZG_RIGHT
		B_CANT_KILL|B_BOX_SLIDE,      //78) CHAR_KRATKA_SLIZG_UP
		B_CANT_KILL|B_BOX_SLIDE,      //79) CHAR_KRATKA_SLIZG_DOWN
		B_ROBBO,                           //80) CHAR_ROBBO_LEFT_B
		B_MON_RIGHT,                       //81) CHAR_MR_UP_A
		B_ROBBO,                           //82) CHAR_ROBBO_RIGHT_A
		B_MON_RIGHT,                       //83) CHAR_MR_LEFT_A
		B_ROBBO,                           //84) CHAR_ROBBO_RIGHT_B
		B_MON_RIGHT,                       //85) CHAR_MR_DOWN_A
		B_ROBBO,                           //86) CHAR_ROBBO_UP_A
		B_ROBBO,                           //87) CHAR_ROBBO_UP_B
		B_MON_LEFT,                        //88) CHAR_ML_UP_A
		B_ROBBO,                           //89) CHAR_ROBBO_DOWN_A
		B_MON_LEFT,                        //90) CHAR_ML_LEFT_A
		B_ROBBO,                           //91) CHAR_ROBBO_DOWN_B
		B_MON_LEFT,                        //92) CHAR_ML_DOWN_A
		B_CANT_KBOM,                       //93) CHAR_BOMBA2
		B_CANT_KILL,                       //94) CHAR_BOOM2
		B_CANT_KILL,                       //95) CHAR_BOOM3
		B_CANT_KILL,                       //96) CHAR_BOOM4
		B_CANT_KILL,                       //97) CHAR_BOOM5
		B_CANT_KILL,                       //98) CHAR_BOOM6
		B_CANT_KILL,                       //99) CHAR_BOOM7
		B_ROBBO,                           //100) CHAR_TELE_BOOM1
		B_ROBBO,                           //101) CHAR_TELE_BOOM2
		B_ROBBO,                           //102) CHAR_TELE_BOOM3
		B_ROBBO,                           //103) CHAR_TELE_BOOM4
		B_CANT_KBOM					    //104) CHAR_SPACE_BLINK
	];


	// INITIAL STRUCT COUPLING BLOCK INDEX WITH IMAGE
	// image index, block index
	blocksImages = [
		[i_EMPTY, 0],
		[i_BOX, CHAR_BOX],
		[i_WALL, CHAR_WALL],
		[i_ELEMENT, CHAR_SCREW],
		[i_ROBBO_LEFT_A, CHAR_ROBBO_LEFT_A],
		[i_ROBBO_LEFT_B, CHAR_ROBBO_LEFT_B],
		[i_ROBBO_RIGHT_A, CHAR_ROBBO_RIGHT_A],
		[i_ROBBO_RIGHT_B, CHAR_ROBBO_RIGHT_B],
		[i_ROBBO_UP_A, CHAR_ROBBO_UP_A],
		[i_ROBBO_UP_B, CHAR_ROBBO_UP_B],
		[i_ROBBO_DOWN_A, CHAR_ROBBO_DOWN_A],
		[i_ROBBO_DOWN_B, CHAR_ROBBO_DOWN_B],
		[i_BOOM_1, CHAR_BOOM_1],
		[i_BOOM_2, CHAR_BOOM_2],
		[i_BOOM_3, CHAR_BOOM_3],
		[i_BOOM_4, CHAR_BOOM_4],
		[i_BOOM_3, CHAR_BOOM_5],
		[i_BOOM_2, CHAR_BOOM_6],
		[i_BOOM_1, CHAR_BOOM_7],
		[i_DOOR, CHAR_DOOR],
		[i_KEY, CHAR_KEY],
		[i_BULLET, CHAR_BULLET],
		[i_GROUND, CHAR_GROUND],
		[i_SLIDE_BOX, CHAR_SLIDE_BOX],
		[i_SLIDE_BOX, CHAR_SLIDE_BOX_LEFT],
		[i_SLIDE_BOX, CHAR_SLIDE_BOX_RIGHT],
		[i_SLIDE_BOX, CHAR_SLIDE_BOX_UP],
		[i_SLIDE_BOX, CHAR_SLIDE_BOX_DOWN],
		[i_BOMB, CHAR_BOMBA],
		[i_BOMB, CHAR_BOMBA2],
		[i_CANNON_LEFT, CHAR_CANNON_LEFT],
		[i_CANNON_RIGHT, CHAR_CANNON_RIGHT],
		[i_CANNON_UP, CHAR_CANNON_UP],
		[i_CANNON_DOWN, CHAR_CANNON_DOWN],
		[i_SURPRISE, CHAR_SURPRISE],
		[i_BOOM_2, CHAR_SURPRISE_BOOM1],
		[i_BOOM_4, CHAR_SURPRISE_BOOM2],
		[i_BOOM_2, CHAR_SURPRISE_BOOM3],
		[i_MAGNEZ_LEFT, CHAR_MAGNEZ_LEFT],
		[i_MAGNEZ_RIGHT, CHAR_MAGNEZ_RIGHT],
		[i_ROBBO_LEFT_A, CHAR_MAG_ROBBO_L],
		[i_ROBBO_RIGHT_A, CHAR_MAG_ROBBO_R],
		[i_BLACK, CHAR_NIC],
		[i_CANNON_RIGHT, CHAR_ROTATE_CANNON_RIGHT],
		[i_CANNON_LEFT, CHAR_ROTATE_CANNON_LEFT],
		[i_CANNON_UP, CHAR_ROTATE_CANNON_UP],
		[i_CANNON_DOWN, CHAR_ROTATE_CANNON_DOWN],
		[i_CANNON_MOVE, CHAR_MOVING_CANNON_L_A],
		[i_CANNON_MOVE, CHAR_MOVING_CANNON_R_A],
		[i_CANNON_LEFT, CHAR_LONG_CANNON_LEFT],
		[i_CANNON_RIGHT, CHAR_LONG_CANNON_RIGHT],
		[i_CANNON_UP, CHAR_LONG_CANNON_UP],
		[i_CANNON_DOWN, CHAR_LONG_CANNON_DOWN],
		[i_CANNON_LEFT, CHAR_BLAST_CANNON_LEFT],
		[i_CANNON_RIGHT, CHAR_BLAST_CANNON_RIGHT],
		[i_CANNON_UP, CHAR_BLAST_CANNON_UP],
		[i_CANNON_DOWN, CHAR_BLAST_CANNON_DOWN],
		[i_BOOM_1, CHAR_BLAST_PROJECTILE_RIGHT],
		[i_BOOM_1, CHAR_BLAST_PROJECTILE_LEFT],
		[i_BOOM_1, CHAR_BLAST_PROJECTILE_UP],
		[i_BOOM_1, CHAR_BLAST_PROJECTILE_DOWN],
		[i_LIVE, CHAR_ADD_LIVE],
		[i_BOOM_1, CHAR_TELE_BOOM1],
		[i_BOOM_2, CHAR_TELE_BOOM2],
		[i_BOOM_3, CHAR_TELE_BOOM3],
		[i_BOOM_4, CHAR_TELE_BOOM4],
		[i_SHIP_A, CHAR_SHIP_A]
	];

	// swap images array
	// block index, image index A, image index B
	swapImages = [
		[CHAR_SHIP_BLINK, i_SHIP_B, i_SHIP_A],

		[CHAR_MR_DOWN_A, i_M_RIGHT_A, i_M_RIGHT_B],
		[CHAR_MR_UP_A, i_M_RIGHT_A, i_M_RIGHT_B],
		[CHAR_MR_LEFT_A, i_M_RIGHT_A, i_M_RIGHT_B],
		[CHAR_MR_RIGHT_A, i_M_RIGHT_A, i_M_RIGHT_B],

//            [CHAR_MR_DOWN_A, i_ROBBO_DOWN_A, i_ROBBO_DOWN_B],
//            [CHAR_MR_UP_A, i_ROBBO_UP_A, i_ROBBO_UP_B],
//            [CHAR_MR_LEFT_A, i_ROBBO_LEFT_A, i_ROBBO_LEFT_B],
//            [CHAR_MR_RIGHT_A, i_ROBBO_RIGHT_A, i_ROBBO_RIGHT_B],

		[CHAR_PROJECTILE_DOWN_A, i_PROJECTILE_DOWN_A, i_PROJECTILE_DOWN_B],
		[CHAR_PROJECTILE_UP_A, i_PROJECTILE_UP_A, i_PROJECTILE_UP_B],
		[CHAR_PROJECTILE_LEFT_A, i_PROJECTILE_LEFT_A, i_PROJECTILE_LEFT_B],
		[CHAR_PROJECTILE_RIGHT_A, i_PROJECTILE_RIGHT_A, i_PROJECTILE_RIGHT_B],
		[CHAR_BIRD_RIGHT_A, i_BIRD_A, i_BIRD_B],
		[CHAR_BIRD_LEFT_A, i_BIRD_A, i_BIRD_B],
		[CHAR_BIRD_UP_A, i_BIRD_A, i_BIRD_B],
		[CHAR_BIRD_DOWN_A, i_BIRD_A, i_BIRD_B],
		[CHAR_ML_DOWN_A, i_M_LEFT_A, i_M_LEFT_B],
		[CHAR_ML_UP_A, i_M_LEFT_A, i_M_LEFT_B],
		[CHAR_ML_LEFT_A, i_M_LEFT_A, i_M_LEFT_B],
		[CHAR_ML_RIGHT_A, i_M_LEFT_A, i_M_LEFT_B],
		[CHAR_FIRE_BIRD_LEFT_A, i_BIRD_FIRE_A, i_BIRD_FIRE_B],
		[CHAR_FIRE_BIRD_RIGHT_A, i_BIRD_FIRE_A, i_BIRD_FIRE_B],
		[CHAR_LONG_PROJECTILE_DOWN_A, i_PROJECTILE_DOWN_A, i_PROJECTILE_DOWN_B],
		[CHAR_LONG_PROJECTILE_UP_A, i_PROJECTILE_UP_A, i_PROJECTILE_UP_B],
		[CHAR_LONG_PROJECTILE_LEFT_A, i_PROJECTILE_LEFT_A, i_PROJECTILE_LEFT_B],
		[CHAR_LONG_PROJECTILE_RIGHT_A, i_PROJECTILE_RIGHT_A, i_PROJECTILE_RIGHT_B],
		[CHAR_LONG_PROJECTILE_DOWN_B, i_PROJECTILE_DOWN_A, i_PROJECTILE_DOWN_B],
		[CHAR_LONG_PROJECTILE_UP_B, i_PROJECTILE_UP_A, i_PROJECTILE_UP_B],
		[CHAR_LONG_PROJECTILE_LEFT_B, i_PROJECTILE_LEFT_A, i_PROJECTILE_LEFT_B],
		[CHAR_LONG_PROJECTILE_RIGHT_B, i_PROJECTILE_RIGHT_A, i_PROJECTILE_RIGHT_B],
		[CHAR_MIRROR_1_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_2_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_3_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_4_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_5_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_6_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_7_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_8_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_9_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MIRROR_10_A, i_MIRROR_A, i_MIRROR_B],
		[CHAR_MOVING_EYES_A, i_EYES_A, i_EYES_B]
	];

	currentBlocksImgIndex = new Array(blocksMask.length);	// in game there is used blocks the same amount as in blocksMask
	for(var i=0; i<blocksImages.length; i++) {
		var blkImgPlace = blocksImages[i];
		currentBlocksImgIndex[blkImgPlace[1]] = blkImgPlace[0];
	}

	// set tile set and ship
	refreshTileSet();
}
	
init();

export function randRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

let tileSetIndex = false;
function refreshTileSet() {

	for(var i=0; i<swapImages.length;i++) {
		var swapImgArr = swapImages[i];
		currentBlocksImgIndex[swapImgArr[0]] = tileSetIndex ? swapImgArr[1] : swapImgArr[2];
	}
	tileSetIndex = !tileSetIndex;
}

// function setWall(wall) {
// 	currentBlocksImgIndex[CHAR_WALL] = i_WALL2 + wall;
// }

// function setRandomWall() {
// 	currentBlocksImgIndex[CHAR_WALL] = i_WALL2 + randRange(0, 12);
// }


export default {
	currentBlocksImgIndex,
	blocksImages,
	refreshTileSet
}

/*  eslint-enable */